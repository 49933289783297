import styled from 'styled-components';

export const BuildFormBackNavigation = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  width: 100%;
  height: 56px;
  padding-left: 36px;
  font-size: 14px;
  line-height: 24px;
  display: flex;

  & + * {
    margin-top: 82px;
  }
`;

export default BuildFormBackNavigation;
