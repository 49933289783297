import React, { useCallback, useState, useEffect } from 'react';
import { IFormImage } from '../../../context-providers/forms/forms-fields';
import { fetchAPI } from '../../../utils/fetch-api';
import { getQueryVar } from '../../../utils/query-vars';
import { FormFooter } from '../../atoms/custom-elements/FormFooter';
import { ContentContainer } from '../../layout/Layout.style';

interface IFormTextProps {
  field: IFormImage;
}

const RenderFormImageFooter = ({ field }: IFormTextProps) => {
  const [blob, setFormInReview] = useState<string | null>(null);

  const fileId = field.props.fileId;
  const formTemplateId = getQueryVar('fid') as string;

  const load = useCallback(async () => {
    const res = await fetchAPI<string>({
      method: 'GET',
      path: 'files/PreviewFormImage/',
      responseType: 'blob',
      urlParams: {
        fileId,
        formTemplateId: formTemplateId || '',
      },
    });
    if (res.success) {
      const fileSrc = URL.createObjectURL(res.body);
      setFormInReview(fileSrc);
    }
  }, [fileId, formTemplateId]);
  useEffect(() => {
    load();
  }, [load]);

  // to-do work out if its a header or footer using field.inputType
  if (!blob)
    return (
      <ContentContainer small>
        <h1>No</h1>
      </ContentContainer>
    );

  return <FormFooter src={blob} />;
};

export default RenderFormImageFooter;
