import { IFormTemplate } from '../context-providers/forms/forms-reducer';
import { fetchAPI } from './fetch-api';

interface IFormTemplateUtil {
  getById(id: string): Promise<IFormTemplate>;
  duplicate(id: string): Promise<IFormTemplate>;
}

const FormTemplateUtil: IFormTemplateUtil = {
  async getById(id: string): Promise<IFormTemplate> {
    const response = await fetchAPI<IFormTemplate>({
      path: `formtemplates/${id}`,
      method: 'GET',
    });
    if (!response.success) {
      throw new Error('Failed to get form template');
    }

    return response.body;
  },
  async duplicate(id: string): Promise<IFormTemplate> {
    const response = await fetchAPI<IFormTemplate>({
      path: `formtemplates/duplicate`,
      method: 'POST',
      body: id,
    });
    if (!response.success) {
      throw new Error('Failed to duplicate form template');
    }

    return response.body;
  },
};

export default FormTemplateUtil;
