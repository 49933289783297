import React, { useCallback, useEffect, useState } from 'react';
import { IFormTemplate } from '../../../context-providers/forms/forms-reducer';
import FormTemplateUtil from '../../../utils/form-template-util';
import { getQueryVar } from '../../../utils/query-vars';
import Stack from '../../atoms/stack/Stack';
import { ContentContainer, LightContentBox } from '../../layout/Layout.style';
import { FormTitleHeader, ReviewFormContainer } from '../Form/Form.style';
import RenderFormTemplate from '../Form/RenderFormTemplate';
import Button from '../../atoms/button/Button';
import { IconChevronLeft } from '../../icons';
import { navigate } from 'gatsby';
import BuildFormBackNavigation from './back-navigation.style';

const ViewForm = () => {
  const [formTemplate, setViewForm] = useState<IFormTemplate | null>(null);

  const formTemplateId = getQueryVar('ftid');

  if (!formTemplateId) {
    throw new Error('Form Template Id is required');
  }

  const routedFromBuildPreview =
    typeof window !== 'undefined' &&
    window.location.href.includes('view-draft-form');
  const routedFromTemplateList =
    typeof window !== 'undefined' && window.location.href.includes('view-form');

  const loadFormTemplate = useCallback(async () => {
    if (!formTemplateId) {
      return;
    }
    const form = await FormTemplateUtil.getById(formTemplateId);
    setViewForm(form);
  }, [formTemplateId]);

  useEffect(() => {
    loadFormTemplate();
  }, [loadFormTemplate]);

  if (formTemplate === null) {
    return null;
  }

  return (
    <>
      {routedFromBuildPreview && (
        <BuildFormBackNavigation>
          <Button
            iconLeft={<IconChevronLeft responsive />}
            onClick={() => {
              navigate(`/build-form/?formType=${formTemplate.formType}`);
            }}
            variant="naked"
          >
            <b>Back to Create Form Template</b>
          </Button>
        </BuildFormBackNavigation>
      )}
      {routedFromTemplateList && (
        <BuildFormBackNavigation>
          <Button
            iconLeft={<IconChevronLeft responsive />}
            onClick={() => {
              navigate(`/list-forms`);
            }}
            variant="naked"
          >
            <b>Back to Form Templates</b>
          </Button>
        </BuildFormBackNavigation>
      )}
      <ReviewFormContainer>
        <ContentContainer small>
          <FormTitleHeader>
            <h1>{formTemplate.formName}</h1>
          </FormTitleHeader>
          <LightContentBox>
            <form>
              <Stack stackMultiplier={3}>
                <RenderFormTemplate form={formTemplate} />
              </Stack>
            </form>
          </LightContentBox>
        </ContentContainer>
      </ReviewFormContainer>
    </>
  );
};

export default ViewForm;
