import styled from 'styled-components';
import { boxShadow, color } from '../../config';

export const FormTitleHeader = styled.header`
  padding: 4rem 2rem;
  display: flex;
  justify-content: space-between;
`;

export const ImageContainer = styled.div`
  background-color: ${color.grey.lightest};
  height: 17rem;
  border-radius: 8px;
`;

export const P = styled.div`
  font-size: 1.8rem;
  line-height: 1.8;
`;

export const Divider = styled.hr`
  border: 0;
  border-top: 2px solid ${color.grey.lightest};
  width: 80%;
  margin-left: auto;
  margin-right: auto;
`;

interface IFormFieldsProps {
  hasMaxWidth?: boolean;
}

export const FormFields = styled.div<IFormFieldsProps>`
  ${({ hasMaxWidth }) => hasMaxWidth && `max-width: 38.2rem;`}
  margin-left: auto;
  margin-right: auto;
`;

export const ReviewFormContainer = styled.div`
  padding-bottom: 78px;
`;

export const ReviewFormPrepopFieldReview = styled.div`
  color: ${color.status.warning};
  p {
    padding-left: 1rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
  }
  outline: solid;
  outline-color: ${color.status.warning};
  outline-width: 1px;
  box-shadow: ${boxShadow.error};
  padding: 0.75em 1em;
  border-radius: 0.5em;
  background-color: ${color.status.warningBg};
  margin-bottom: 2rem;
`;
