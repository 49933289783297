import React, { useCallback, useState, useEffect } from 'react';
import { IFormImage } from '../../../context-providers/forms/forms-fields';
import { fetchAPI } from '../../../utils/fetch-api';
import { getQueryVar } from '../../../utils/query-vars';
import { FormLogo } from '../../atoms/custom-elements/FormLogo';
import { Row } from '../../layout/Layout.style';

interface IFormTextProps {
  field: IFormImage;
}

const RenderFormImageHeader = ({ field }: IFormTextProps) => {
  const [blob, setFormInReview] = useState<string | null>(null);

  const fileId = field.props.fileId;
  const formTemplateId = getQueryVar('fid') as string;

  const load = useCallback(async () => {
    const res = await fetchAPI<string>({
      method: 'GET',
      path: 'files/PreviewFormImage/',
      responseType: 'blob',
      urlParams: {
        fileId,
        formTemplateId: formTemplateId || '',
      },
    });
    if (res.success) {
      const fileSrc = URL.createObjectURL(res.body);
      setFormInReview(fileSrc);
    }
  }, [fileId, formTemplateId]);
  useEffect(() => {
    load();
  }, [load]);

  if (!blob) return null;

  return (
    <Row>
      <FormLogo src={blob} />
    </Row>
  );
};

export default RenderFormImageHeader;
