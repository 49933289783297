import produce from 'immer';
import React, { useEffect, useState } from 'react';
import { IssueStatus } from '../../../context-providers/enums/global-enums';
import { TFormItemCompletedBy } from '../../../context-providers/forms/form-types';
import { IFormTable } from '../../../context-providers/forms/forms-fields';
import { IFormTemplate } from '../../../context-providers/forms/forms-reducer';
import { Box } from '../../atoms/custom-elements/Box.style';
import Stack from '../../atoms/stack/Stack';
import { RowCenter, WordWrapBox } from '../../layout/Layout.style';
import { FormFields } from './Form.style';
import FormField from './FormField';
import FormText from './FormText';
import RenderFormImageFooter from './RenderFormImageFooter';
import RenderFormImageHeader from './RenderFormImageHeader';
import {
  ILearnerEthnicity,
  ILearnerLLDD,
  ILearnerSex,
} from '../../../context-providers/learner/learner-reducer';
import ReferenceDataHelper from '../../../utils/reference-data-helper';

interface IRenderFormTemplate {
  form: IFormTemplate;
  formId: string | number;
  userType?: TFormItemCompletedBy;
  setForm?: (arg1: IFormTemplate) => void;
  reviewing?: boolean;
  hasCorrections?: boolean;
  isFormApproval?: boolean;
}

const RenderFormTemplate = ({
  form,
  formId,
  setForm,
  userType,
  reviewing,
  hasCorrections = false,
  isFormApproval,
}: IRenderFormTemplate) => {
  const [lldds, setLldds] = useState<ILearnerLLDD[]>([]);
  const [llddsLoaded, setLlddsLoaded] = useState<boolean>(false);

  const [ethnicities, setEthnicities] = useState<ILearnerEthnicity[]>([]);
  const [ethnicitiesLoaded, setEthnicitiesLoaded] = useState<boolean>(false);

  const [sex, setSex] = useState<ILearnerSex[]>([]);
  const [sexLoaded, setSexLoaded] = useState<boolean>(false);

  const loadLLDDs = (): void => {
    if (!llddsLoaded) {
      ReferenceDataHelper.getLllds().then((response) => {
        setLldds(response);
        setLlddsLoaded(true);
      });
    }
  };

  const loadEthnicities = (): void => {
    if (!ethnicitiesLoaded) {
      ReferenceDataHelper.getEthnicities().then((response) => {
        setEthnicities(response);
        setEthnicitiesLoaded(true);
      });
    }
  };

  const loadSex = (): void => {
    if (!sexLoaded) {
      ReferenceDataHelper.getSex().then((response) => {
        setSex(response);
        setSexLoaded(true);
      });
    }
  };

  useEffect(() => {
    loadSex();
  }, [sexLoaded]);

  useEffect(() => {
    loadEthnicities();
  }, [ethnicitiesLoaded]);

  useEffect(() => {
    loadLLDDs();
  }, [llddsLoaded]);

  // update a field
  const onChange = (fieldIndex: number, value: string) => {
    if (setForm) {
      setForm(
        produce(form, (newForm) => {
          const field = newForm.formData[fieldIndex];
          if (newForm && field.itemType === 'input') {
            field.props.value = value;
          }
          if (newForm && field.itemType === 'prepop') {
            field.props.value = value;
            field.props.newValue = value;
          }
          if (field.issue) {
            field.issue.status = IssueStatus.Raised;
            field.issue.hasBeenModified = true;
          }
          return newForm;
        }),
      );
    }
  };

  const onDataTableChange = (dataTable: IFormTable, index: number): void => {
    if (setForm) {
      setForm(
        produce(form, (newForm) => {
          newForm.formData[index].props = dataTable.props;
          return newForm;
        }),
      );
    }
  };

  const onStatusChange = (status: IssueStatus, index: number) => {
    if (setForm) {
      setForm(
        produce(form, (newForm) => {
          const field = newForm.formData[index];
          if (
            (newForm && field.itemType === 'input') ||
            field.itemType === 'prepop'
          ) {
            if (field.issue) {
              field.issue.status = status;
            }
          }
          return newForm;
        }),
      );
    }
  };
  return (
    <>
      <Stack stackMultiplier={2}>
        <>
          {form.formData
            .filter((item) => item.inputType === 'Header')
            .map((field, index: number) => (
              <Box key={`header-${index}`} margin="20px">
                <RenderFormImageHeader field={field} />
              </Box>
            ))}
        </>
        {form.formData.map((field, key) =>
          field.itemType === 'text' ? (
            <WordWrapBox key={field.id}>
              <FormText field={field} isVisible />
            </WordWrapBox>
          ) : (
            <WordWrapBox key={field.id}>
              <FormFields hasMaxWidth={field.inputType !== 'DataTable'}>
                <FormField
                  formId={formId}
                  userType={userType}
                  reviewing={reviewing}
                  index={key}
                  field={field}
                  isRejectForm={form.isRejectForm}
                  formHasCorrections={hasCorrections}
                  form={form}
                  onChange={(val: string) => {
                    onChange(key, val);
                  }}
                  onStatusChange={onStatusChange}
                  onDataTableChange={(dataTable) =>
                    onDataTableChange(dataTable, key)
                  }
                  isFormApproval={isFormApproval}
                  sexOptions={sex}
                  llddOptions={lldds}
                  ethnicityOptions={ethnicities}
                  isEnquiry={userType === 'enquiry'}
                />
              </FormFields>
            </WordWrapBox>
          ),
        )}
        <RowCenter>
          {form.formData
            .filter((item) => item.inputType === 'Footer')
            .map((field, index: number) => (
              <Box key={`footer-${index}`} margin="10px">
                <RenderFormImageFooter field={field} />
              </Box>
            ))}
        </RowCenter>
      </Stack>
    </>
  );
};

export default RenderFormTemplate;
